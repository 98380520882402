<template>
  <div class="uk-flex uk-flex-middle" uk-height-viewport>
    <div class="uk-width-2-3@m uk-width-1-2@s m-auto rounded">
      <div class="login-card uk-child-width-1-2@m uk-grid-collapse" uk-grid> <!--bg-gradient-grey-->
        <div class="uk-margin-auto-vertical uk-text-center uk-animation-scale-up p-5 uk-light">
          <template v-if="company">
            <img :src="company.logo_link" class="company-logo" />
            <h3 class="mb-4">{{ company.name }}</h3>
            <p class="d-none">{{ company.type }}</p>
          </template>
        </div>

        <div class="uk-card-default p-5 rounded">
          <div class="mb-2 d-flex justify-content-end" v-if="company && company.is_allowed_register_user">
            <a href="javascript:void(0)" @click="$router.push({ name: 'index.register' })"
              class="btn btn-outline-primary"><i class="icon-feather-user-plus"></i>
              {{ $t("general.Sing_In") }}</a>
          </div>
          <div class="mb-4 uk-text-center">
            <h3 class="mb-0">{{ $t("login.header") }}</h3>
            <p class="my-2" style="font-size:.875rem;">
              {{ $t("login.header_text") }}
            </p>
          </div>
          <form @submit.prevent="onSubmit()">
            <div class="uk-form-group">
              <label class="uk-form-label" for="dob">{{
            $t('profile.login_type')
          }}</label>
              <select name="login_type" class="form-control" v-model="form.login_field"
                @change="changeUsername(form.login_field)">
                <option v-for="item in login_types" :data-icon="item.icon" :value="item.type"> {{

            $t(item.name) }}
                </option>
              </select>
            </div>

            <div class="uk-form-group">
              <label class="uk-form-label">
                {{ form.username }}
              </label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-user"></i>
                </span>
                <input class="uk-input" type="text" v-model="form.email" />
              </div>
            </div>

            <div class="uk-form-group">
              <label class="uk-form-label">
                {{ $t("general.password") }}
              </label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-lock"></i>
                </span>
                <input class="uk-input" v-model="form.password" :type="passwordType ? 'text' : 'password'" />
                <i class="icon-feather-eye open-password" @click="showPassword('passwordType')"></i>
              </div>
            </div>

            <div class="uk-alert-danger" uk-alert v-if="errors || errorMessage">
              <a class="uk-alert-close" uk-close></a>
              <p style="color:red;">
                <i class="uil-exclamation-triangle mr-2"></i>{{ errors ? errors : errorMessage }}
              </p>
            </div>

            <div class="mt-4 uk-flex-middle uk-grid-small" uk-grid>
              <div class="uk-width-expand@s">
                <button type="button" class="btn" @click="$router.push({ name: 'index.forgot-password' })"><i
                    class="uil-key-skeleton"></i> {{ $t("login.forgot_password") }}</button>

                <!-- <a @click="$router.push({ name: 'index.forgot-password' })" style="text-decoration:underline;color:#666CFF;text-transform:capitalize;">
                  <i class="uil-key-skeleton"></i> {{ $t("login.forgot_password") }}</a> -->
              </div>
              <div class="uk-width-auto@s">
                <button id="login_submit_button" ref="login_submit_button" type="submit" class="btn btn-default"
                  :disabled="is_loading">
                  <span uk-spinner="ratio : .4" class="mr-2" v-if="is_loading"></span>{{ $t("login.header") }}
                </button>
              </div>
            </div>
            <!-- <vue-recaptcha
              ref="recaptcha"
              :sitekey="sitekey"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
            /> -->
          </form>
        </div>
      </div>
      <img :src="loginLogoFooter" alt="" class="d-block mt-5" style="margin:0 auto;width:160px;" />
    </div>
  </div>
</template>
<script>
import { GET_ITEM_DETAIL_BY_ID as COMPANY_DETAIL } from "@/core/services/store/REST.module";
import {
  ERROR,
  LOGIN,
  PURGE_AUTH,
  SEND_SMS,
  SET_ERROR,
} from "@/core/services/store/auth.module";
import { validationMixin } from "vuelidate";
import store from "@/core/services";
import SecureLS from "secure-ls";
import axios from "axios";

// import { VueRecaptcha } from "vue-recaptcha";

const ls = new SecureLS({ encodingType: "aes", isCompression: true });

export default {
  name: "AuthLayout",
  mixins: [validationMixin],
  // components: { VueRecaptcha },
  beforeCreate() {
    if (["production", "staging"].includes(process.env.NODE_ENV)) {
      let self = this;
      let url = window.location.href;
      const alias = window.location.hostname.split(".")[0];
      if (!url.includes("www.")) {
        this.$store
          .dispatch(COMPANY_DETAIL, {
            url: "api/company/info",
            filters: {
              alias: alias,
            },
          })
          .then((result) => {
            if (result.status) {
              if (+Object.keys(result.data).length !== 0) {
                self.company = result.data;
              } else {
                window.location.href = process.env.VUE_APP_APP_URL;
              }
            }
          });
      }
    }
  },
  mounted() {
    // this.getLoginTypes();

    let url = window.location.href;
    if (url.includes("www.")) {
      this.company = {
        logo_link: "/media/images/anzera-logo-medium.png",
        name: "Anzera Öğrenme Teknolojileri A.Ş.",
        type: "Dijital Öğrenme Platformu",
        is_allowed_register_user: 0,
      };
    }

    this.$store.commit(SET_ERROR, "");
    let version = process.env.VUE_APP_VERSION;
    let localVersion = ls.get("version");
    if (!localVersion || version !== localVersion) {
      // window.localStorage.clear();
      ls.set("version", version);
      // location.reload();
    }
  },
  data() {
    return {
      login_types: [{
        name: 'general.login_with_email', type: "email", icon: 'uil-envelope', username: 'E-mail'
      },
      {
        name: 'general.login_with_phone_number', type: "phone", icon: 'uil-phone', username: 'Telefon'
      }, {
        name: 'general.login_with_id_number', type: "national_id", icon: 'uil-postcard', username: 'Tc kimlik No'
      },
      {
        name: 'general.login_with_registration_number', type: "registration_number", icon: 'uil-keyboard', username: 'Sicil Numarası'
      }],
      errorMessage: "",
      com: [],
      is_loading: false,
      form: {
        email: "",
        password: "",
        login_field: "email",
        username: "E-mail"
        // recaptcha: "",
      },
      company: {
        logo_link: "/media/images/anzera-logo-medium.png",
        name: "Anzera Öğrenme Teknolojileri A.Ş.",
        type: "Dijital Öğrenme Platformu",
        is_allowed_register_user: 0,
      },
      passwordType: false,
      // sitekey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
    };
  },
  computed: {
    loginLogoFooter() {
      return process.env.BASE_URL + "media/images/logo-dark.svg";
    },
    errors() {
      return store.getters[ERROR];
    },
    loginLogo() {
      return process.env.BASE_URL + "assets/media/logos/logo-letter-13.png";
    },
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-3.jpg";
    },
  },
  methods: {
    changeUsername(event) {
      this.login_types.every((data) => {
        if (data.type === event) {
          this.form.username = data.username
          return false
        } else {
          return true
        }
      })
    },
    // getLoginTypes() {
    //   let types = []
    //   axios.get(`api/user/login-types`, {
    //     headers: this.apiHeaders
    //   }).then((result) => {
    //     result.data.forEach(data => {
    //       types.push({
    //         id: data.id,
    //         name: data.name
    //       })
    //     });
    //   })
    //   this.login_types = types
    // },
    // onCaptchaVerified: function(recaptchaToken) {
    //   this.form.recaptcha = recaptchaToken;
    //   this.validateCaptcha = true;
    // },
    // onCaptchaExpired: function() {
    //   this.$refs.recaptcha.reset();
    // },
    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },
    showPassword(element) {
      switch (element) {
        case "passwordType":
          this.passwordType = !this[element];
          break;
        case "confirmPasswordType":
          this.confirmPasswordType = !this[element];
          break;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      let redirectUrl = this.$route.query.redirect;
      if (redirectUrl && typeof redirectUrl !== "undefined") {
        window.localStorage.setItem("redirect_url", redirectUrl);
      }

      let alias = process.env.VUE_APP_DEFAULT_SUBDOMAIN;
      if (["production", "staging"].includes(process.env.NODE_ENV)) {
        alias = window.location.hostname.split(".")[0];
      }

      const username = this.form.email;
      const password = this.form.password;
      const login_field = this.form.login_field;
      // const recaptcha = this.form.recaptcha;

      if (username.length < 3) {
        this.errorMessage = this.$t("validation.required_field").replace(
          ":NAME",
          this.$t("general.username")
        );
        return false;
      }

      if (password.length < 3) {
        this.errorMessage = this.$t("validation.required_field").replace(
          ":NAME",
          this.$t("general.password")
        );
        return false;
      }

      // set spinner to submit button
      this.is_loading = true;
      // clear existing errors
      this.$store.commit(PURGE_AUTH);

      // send login request
      this.$store
        // .dispatch(LOGIN, { username, password, alias, recaptcha })
        .dispatch(LOGIN, { username, password, alias, login_field })
        .then((response) => {
          this.is_loading = false;
          // this.onCaptchaExpired();
          if (response.status === 200) {
            // send login request
            let redirectUrl = window.localStorage.getItem("redirect_url");
            if (redirectUrl && typeof redirectUrl !== "undefined") {
              this.$router.push({ path: redirectUrl });
            } else {
              this.$router.push({ path: "/dashboard" });
            }
          } else {
            this.$router.push({
              name: "index.confirm-access-code",
              params: {
                user: {
                  username: username,
                  password: password,
                  login_field: login_field
                  // recaptcha: recaptcha,
                },
              },
            });
          }
        });
    },
  },
  watch: {
    errors(val) {
      if (val) this.is_loading = false;
    },
  },
};
</script>

<style scoped>
.open-password {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.open-password:hover {
  cursor: pointer;
}
</style>
